import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getDetails } from '../../selectors/step';
import { DescriptionFieldSet } from '@wix/ambassador-challenges-v1-challenge/types';
import { requestStepV3 } from '../storage-contexts/Challenge/api/requestStepV3';
import { Step } from '../../types/v3Types';

export type StepDescriptionMap = { [participantStepId: string]: string };

export const stepDescriptionMap: StepDescriptionMap = {};

export const updateStepDescriptionMap = (
  sourceStep: Step,
  participantStepId: string,
) => {
  stepDescriptionMap[participantStepId] = getDetails(sourceStep);
};

export async function requestParticipantStep(
  stepId: string,
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  const step = await requestStepV3(
    { stepId, descriptionFieldSet: DescriptionFieldSet.EXTENDED },
    flowAPI,
  );
  updateStepDescriptionMap(step, stepId);

  flowAPI.controllerConfig.setProps({
    stepDescriptionMap,
  });
}
